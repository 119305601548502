<template>
    <div class="range-input-container" @keypress.enter="prevent">
        <el-input
            class="range-input"
            :placeholder="plc[0]"
            v-model="from"
            @blur="changeRange"
        />
        <span class="split">-</span>
        <el-input
            class="range-input"
            :placeholder="plc[1]"
            v-model="to"
            @blur="changeRange"
        />
    </div>
</template>

<script>
/**
 *
 * range组件不支持回车搜索，需要在两个输入框输入完毕后
 * 执行大小交换操作
 *
 */
import { RESET_SEARCH } from "../../views/index/events/eventBus";
export default {
    data() {
        return {
            from: "",
            to: ""
        };
    },
    props: {
        item: {
            type: Object,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        plc() {
            return this.item.placeholder || ["", ""];
        }
    },
    mounted() {
        this.$EventBus.$on(RESET_SEARCH, this.reset);
    },
    methods: {
        changeRange() {
            if (
                !!this.from &&
                !!this.to &&
                parseInt(this.from) > parseInt(this.to)
            ) {
                [this.from, this.to] = [this.to, this.from];
            }
            if (this.item.keys) {
                this.data[this.item.keys[0]] = this.from;
                this.data[this.item.keys[1]] = this.to;
            }
            this.data[this.item.key] = [this.from, this.to];
        },
        prevent() {
            this.changeRange();
        },
        reset() {
            this.from = "";
            this.to = "";
        }
    }
};
</script>

<style lang="scss">
.range-input-container {
    display: flex;
    height: 36px;
    justify-content: space-between;
    .range-input {
        width: calc(50% - 10px);
    }
    .split {
        line-height: 36px;
    }
}
</style>